import styles from './Downloaddorsia.module.css';
import DorsiaLogo from '@/assets/images/App_Icon.png';
import AppStore from '@/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg?react';
import clsx from 'clsx';
import Config from '@/lib/Config.ts';

export default function DownloadDorsia({
  reservation,
  isEvent = false,
}: {
  reservation?: number | null;
  isEvent: boolean;
}) {
  function getDownloadLink() {
    const prodLink = 'https://dorsia.app.link/referral_hwood';
    const devLink = 'https://r0cio.app.link/referral_hwood';
    const standardLink =
      'https://apps.apple.com/us/app/dorsia-members-club/id1589267208';

    if (!reservation) {
      return standardLink;
    }

    if (reservation === 322) {
      if (
        Config.data.environment === 'local' ||
        Config.data.environment === 'staging'
      ) {
        return devLink;
      }
    }

    if (reservation === 494 && Config.data.environment === 'production') {
      return prodLink;
    }

    return standardLink;
  }

  const link = getDownloadLink();
  console.log('link', link);

  return (
    <div className={styles.downloadSection}>
      <a
        className={clsx('section', styles.downloads)}
        href={getDownloadLink()}
        target="_blank"
      >
        <div className={styles.applogo}>
          <img src={DorsiaLogo} alt="Dorsia Logo" width={32} height={32} />
        </div>

        <h3>Download Dorsia</h3>

        {reservation && isEvent ? (
          <p className={styles.apppromo}>
            Download the app for access to top restaurant reservations.
          </p>
        ) : (
          <p className={styles.apppromo}>
            You are pre-approved for Dorsia membership. Download the app for
            access to top restaurant reservations.
          </p>
        )}
        <AppStore width={120} />
      </a>
    </div>
  );
}
